.tt {
    &-menu {
        &-item {
            border: 2px solid #D10C53;
            border-radius: 20px;
            flex: 2;
            display: block;
            background: rgb(63,55,152);
            background: linear-gradient(180deg, rgba(63,55,152,1) 0%, rgba(28,24,71,1) 100%);
            padding: 1em;

            &--image {
                max-width: 100px;
                height: 100px;
                margin: auto;

                img {
                    width: 100%;
                }
            }

            &--name {
               color: #fff;
               text-align: center;
               font-weight: bold;
            }
        }
    }
}

