.tt-select2 {
    ~ {
        .select2-container {
            width: 100% !important;
        }
    }
}

.select2-container--default {
    .select2-selection--single {
        &.tt-select2-inner {
            border: 1px solid #fff;
            background-color: transparent !important;
            padding: 0.5em;
            border-radius: 0;
            height: 42px;

            .select2-selection__rendered {
                color: #fff;
                font-size: 16px;
            }

            .select2-selection__arrow {
                top: 50%;
                transform: translateY(-50%);
                width: 25px;
                right: 5px;

                b {
                    border-color: #fff transparent transparent transparent;
                    border-width: 7px 6px 0 6px;
                }
            }
        }
    }

    .select2-results__option--highlighted {
        &.select2-results__option--selectable {
            background-color: #8738EC;
        }
    }

    .select2-results__option--selected {
        background-color: #19123a;
    }

    .select2-search--dropdown {
        .select2-search__field {
            background-color: transparent;
            border-top : 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid #fff;
            color: #fff;
            font-family: $font-main;
            font-size: 16px;

            &:focus {
                outline: none;
            }
        }
    }
}

.select2-dropdown {
    background: #08022B;
    border-radius: 0;
}