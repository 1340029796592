$font-main : 'Noto Sans Thai', sans-serif;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  // background-image: url(#{$baseUrl}asset/image/become-a-web-developer-today.jpg);
  // background-size: cover;
  font: 14px $font-main;
  color: #fff;

  img {
    width: 100%;
  }
}

body {
  background-color: #03001a;
}

.tt {
  &-content {
    position: relative;
  }
  &-logo {
    max-width: 120px;
    margin: 3em auto;

    img {
      width: 100%;
    }
  }

  &-section {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 2em;
  }

  &-title {
    text-align: center;

    h2 {
      margin-bottom: 0;
    }
  }

  &-subtitle {
    text-align: center;

    p {
      margin-top: .25em;
      font-weight: 400;
    }
  }

  &-form {
    &--imagefile {
      padding: 2em;
      text-align: center;
    }

    &-inner {
      margin-top: 1em;
      margin-bottom: 2em;
    }

    &--label {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: .5em;
    }

    &s {
      input {
        &[type="text"] {
          width: 100%;
          border: 1px solid #fff;
          padding: .5em;
          background-color: transparent;
          color: #fff;
          font-size: 16px;
          font-family: $font-main;
          border-radius: 0;

          &::placeholder {
            font-size: 16px;
            font-family: $font-main;
          }
        }
      }

      #hbd-file {
        display: none;
        margin: 0 auto;
      }
    }
  }
}


.flex-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}


button ,
input {
  &.btn-main {
    margin-top: 1em;
    margin-bottom: 1em;
    background: rgb(135,56,236);
    background: linear-gradient(180deg, rgba(135,56,236,1) 0%, rgba(83,0,189,1) 100%);
    font-family: $font-main;
    font-weight: 700;
    color: #fff;
    border: 0;
    width: 100%;
    padding: .55em 1em;
    text-align: center;
    font-size: 16px;
    border-radius: 0;
  }
}

.d-none {
  display: none;
}

.red {
  color: red;
}

input {
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
}


.control-btn {
  &.pair {
    flex: 2;
  }
  a {
    &.btn-main {
      margin-top: 1em;
      margin-bottom: 1em;
      background: rgb(251,79,117);
      background: linear-gradient(180deg, rgba(251,79,117,1) 0%, rgba(195,41,255,1) 100%);
      font-family: $font-main;
      font-weight: 700;
      color: #fff;
      border: 0;
      width: 100%;
      padding: .55em 1em;
      text-align: center;
      font-size: 16px;
      border-radius: 0;
      display: block;

      &.purple {
        background-color: #8738EC !important;
        background: #8738EC !important;
      }

      &.red {
        background: rgb(236,151,56);
        background: linear-gradient(180deg, rgba(236,151,56,1) 0%, rgba(189,0,0,1) 100%);
      }
    }
  }

  button {
    display: block;
  }
}

a {
  &.btn-main {
    margin-top: 1em;
    margin-bottom: 1em;
    background: rgb(251,79,117);
    background: linear-gradient(180deg, rgba(251,79,117,1) 0%, rgba(195,41,255,1) 100%);
    font-family: $font-main;
    font-weight: 700;
    color: #fff;
    border: 0;
    width: 100%;
    padding: .55em 1em;
    text-align: center;
    font-size: 16px;
    border-radius: 0;
    display: block;

    &.purple {
      background-color: #8738EC !important;
      background: #8738EC !important;
    }

    &.error {
      background: red !important;
    }
  }
}

body {
  min-height: 100vh;
}

.tt-content {
  min-height: 100vh;
}