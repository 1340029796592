.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: #fff;
    --bs-body-bg: #000;
    --bs-modal-bg: var(--bs-body-bg);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: var(--bs-border-width);
    --bs-modal-border-radius: var(--bs-border-radius-lg);
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: var(--bs-border-width);
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: var(--bs-border-width);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: var(--bs-modal-zindex);

    &.show {
        .modal-dialog {
            transform: none;
        }
    }

    &.fade {
        .modal-dialog {
            transition: transform .3s ease-out;
        }
    }
  }
  
  // Shell div to position the modal with bottom padding
  .modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;
  
    // When fading in the modal, animate it to slide down
    .modal.fade & {
        transition: opacity .15s linear;
    }
    .modal.show & {
        transition: opacity .15s linear;
    }
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    }
  
  
  .modal-dialog-scrollable {
    .modal-content {
      max-height: 100%;
      overflow: hidden;
    }
  
    .modal-body {
      overflow-y: auto;
    }
  }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }
  
  // Actual modal
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; 
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
  }
  
  // Modal background
  .modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);

    &.show {
        opacity: var(--bs-backdrop-opacity);
    }
  }
  
  .lightbox {
    padding: 1em;

    .modal-dialog {
        .modal-content {
            padding: 1em;
            position: relative;

            .lightbox-btn-close {
                position: absolute;
                top: -3em;
                right: -3em;
                background-color: transparent;
                border: 0;
                width: 60px;
                height: 60px;

                img {
                    width: 100%;
                }
    
                &:focus {
                    outline: none;
                }
            }

            .modal-header {
                text-align: center;
                

                h3 {
                    margin-bottom: 0;
                }

                p {
                    margin-top: 0;
                }
            }
        }
    }
    &-image {
        &--wrapper {
            max-width: 300px;
            margin: auto;
            padding: 1em;

            img {
                outline: 1px dotted #fff;
            }
        }
    }

    &-body {
      &-icon {
        width: 64px;
        height: 64px;
        margin: 1.5em auto;

        img {
          width: 100%;
        }
      }

      h3 {
        text-align: center;
      }
    }
  }