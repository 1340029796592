.tt {
    &-form {
        &--imagefile {
            width: 100%;
            margin: auto;
        }

        &--label {
          &.ig {
            position: relative;
            padding-left: 2.5em;

            span {
              &.label-icon {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                width: 35px;
                height: 35px;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        &-radios {
            &--radio {
                flex: 0 0 47%;
                max-width: 47%;

                label {
                  display: block;
                }

                input {
                  &[type="radio"] {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                  }

                  &:checked {
                    ~ img {
                      outline: 2px solid #FF027C;
                    }
                  }
                }
            }
        }
      &s {
        &-radios {
          label {
            position: relative;
            padding-left: 2em;

            span {
              font-weight: bold;
            }

            input {
              &.toggleTab {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:disabled {
                  color: #2b2b2b;
                }

                &:checked {
                  ~ .checkmark {
                    &:after {
                      content: '';
                      position: absolute;
                      display: block;
                      background-color: #8738EC;
                      width: 10px;
                      height: 10px;
                      border-radius: 50%;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%,-50%);
                    }
                  }
                }

                &:disabled {
                  color: #7b7b7b;

                  ~ .checkmark { 
                    border: 1px solid #7b7b7b;
                  }

                  ~ span {
                    color: #7b7b7b;
                  }
                }
              }
            }

            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 17px;
              width: 17px;
              border: 1px solid #f9f9f9;
              border-radius: 50%;
            }
          }
        }
      }
    }
}

.image-wrapper {
    --aspect-ratio: 100%;
    position: relative;
    border: 2px dotted #8738EC;

    &.preview-card {
      --pr-aspect-ratio: 38%;

      &::before {
          content: "";
          display: block;
          padding-top: var(--pr-aspect-ratio);
      }
    }

    &::before {
        content: "";
        display: block;
        padding-top: var(--aspect-ratio);
    }

    &:after {
        content: '';
        display: none;
        background-image: url('./asset/image/icon-upload.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 0;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 100%;
        max-height: 100%;
        width: inherit !important;
        z-index: 1;
    }

    &.image-upload {
       position: relative;

       span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        font-size: 16px;
      }

       input {
        &[type="file"] {
          display: none;
          opacity: 0;
          cursor: pointer;
        }
       }
    }
}
.crop-image {
    display: none;
    background-color: #FF027C;
    padding: .5em 1em;
    font-family: $font-main;
    color: #fff;
    border: 0;
    margin-right: 1em;
    margin-top: 1em;
    font-size: 16px;
    height: 45px;
    border-radius: 8px;
  
    &.active {
      display: inline-block;
    }
  }
  
  
  #upload {
    background-color: #FF027C;
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 1em;
    font-family: $font-main;
    color: #fff;
    border: 0;
    padding-top: .55em;
    padding-left: .55em;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
    height: 45px;
    position: relative;
  }
  
  img {
    &.hidden {
      display: none;
    }
  }