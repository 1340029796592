.tt {
    &-navbar {
        background-color: #000;
        color: #fff;
        padding-top: .75em;
        padding-bottom: .75em;
        min-height: 50px;
        display: flex;
        align-items: center;
        position: relative;

        h1 {
            text-align: center;
            margin: 0;
            font-size: 20px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }

        &-icon {
            &--prev {
                width: 30px;
                height: 30px;
                
                img {
                    width: 100%;
                }
            }
        }
    }
}