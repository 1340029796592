.tt-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    &--rail {
        position: relative;
        width: 100px;
        height: 100px;
        border: 2px solid #200147;
        border-top: 2px solid #FF027C; 
        border-radius: 50%;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
        z-index: 99;
        
    }

    &--logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -47%);
        width: 102.5px;
        height: 102.5px;    
        z-index: 98;

        img {
            width: 100%;
        }
    }
}


@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

.tt-preview-section {
    position: relative;
    animation-name: fade-in-up;
    animation-duration: 1s;
}

@-webkit-keyframes fade-in-up {
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
}

@keyframes fade-in-up {
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
}